import React from 'react';
import ReactMarkdown from 'react-markdown';
import OutboundLink from './OutboundLink';

function Note (props) {
    //let date = new Date(props.note.date).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
    let date = props.note.date;
    return (
        <>
            <ReactMarkdown children={date + ' - ' + props.note.text}
                components={{
                    a: ({node, ...props}) => <OutboundLink eventLabel={props.href+"##"} to={props.href} target="_blank" {...props} />,
                    em: ({node, ...props}) => <em style={{color: '#666', fontStyle: 'normal'}} {...props} />,
                    strong: ({node, ...props}) => <strong style={{fontWeight: 'normal', color: '#fff'}} {...props} />
                }}
            />
               {props.note.image ? <img style={{width: '100%', maxWidth: '400px', display: 'block', padding: '6px'}} alt={props.note.imageAlt} src={props.note.image} /> : ''}
        </>
    );
}

export default Note;
