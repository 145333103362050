import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Release from './Release';
import Note from './Note';
import './App.css';

const TRACKING_ID = 'G-FJYCTES7CQ';
ReactGA.initialize( TRACKING_ID, { debug: false });

function App() {
    const [releases, setReleases] = useState([]);
    const [notes, setNotes] = useState([]);
    const [filter, setFilter] = useState([]);

    const get = (url, callback) => {
        fetch(url,
            {
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                 }
            })
            .then(function(response){
                return response.json();
            })
            .then(function(myJson) {
                callback(myJson)
            });
    }


    useEffect(()=> {
        //getData()
        let query = {};

        //console.log('filter', filter);

        if(typeof filter.commercial !== 'undefined') {
            if(filter.commercial === 'Y') { query.commercial = 'Y' }
            if(filter.commercial === 'D') { query.commercial = 'D' }
            if(filter.commercial === 'N') { query.commercial = 'N' }
        }

         if(typeof filter.owned !== 'undefined') {
            if(filter.owned === 'Y') { query.owned = 'Y' }
            if(filter.owned === 'N') { query.owned = 'N' }
            if(filter.owned === 'P') { query.owned = 'P' }
            if(filter.owned === 'O') { query.owned = 'O' }
            if(filter.owned === 'W') { query.owned = 'W' }
        }

         if(typeof filter.format !== 'undefined') {
            if(filter.format === 'D') { query.format = 'D' }
            if(filter.format === 'T') { query.format = 'T' }
            if(filter.format === 'F') { query.format = 'F' }
            if(filter.format === 'C') { query.format = 'C' }
        }

        if(typeof filter.order !== 'undefined') {
            if(filter.order === '') { query.order = 'A' }
            if(filter.order === 'Y') { query.order = 'Y' }
            if(filter.order === 'N') { query.order = 'N' }
        }

        //console.log('query', query);

        const queryString = Object.keys(query).reduce((acc, key) => acc + `&${key}=${encodeURIComponent(query[key])}`, '?');

        //console.log('queryString', queryString);

        get('/api/releases' + queryString, setReleases)
    }, [filter]);

    useEffect(()=> {
        //getNotes()
        get('/api/notes', setNotes)
    }, []);


    return (
        <div className='App'>
            <div className='banner'></div>
            <header>
                <h1>My Collection of Modern Commercial Commodore 64 Games <i>PLUS Selected Free Games</i></h1>
            </header>

            <div className='Content'>
                <article className='releases'>
                    <>
                        <div className='filters'>

                            <Filter selected={!filter.commercial} onClick={() => setFilter({...filter, 'commercial': ''})}>All</Filter>
                            <Filter selected={filter.commercial === 'Y'} onClick={() => setFilter({...filter, 'commercial': 'Y'})}>Commercial</Filter>
                            <Filter selected={filter.commercial === 'D'} onClick={() => setFilter({...filter, 'commercial': 'D'})}>NYOP</Filter>
                            <Filter selected={filter.commercial === 'N'} onClick={() => setFilter({...filter, 'commercial': 'N'})}>FREE</Filter>

                            <div></div>

                            <Filter selected={!filter.owned} onClick={() => setFilter({...filter, 'owned': ''})}>All</Filter>
                            <Filter selected={filter.owned === 'Y'} onClick={() => setFilter({...filter, 'owned': 'Y'})}>Owned</Filter>
                            <Filter selected={filter.owned === 'O'} onClick={() => setFilter({...filter, 'owned': 'O'})}>On Order</Filter>
                            <Filter selected={filter.owned === 'P'} onClick={() => setFilter({...filter, 'owned': 'P'})}>Pre-Ordered</Filter>
                            <Filter selected={filter.owned === 'N'} onClick={() => setFilter({...filter, 'owned': 'N'})}>Not Owned</Filter>
                            <Filter selected={filter.owned === 'W'} onClick={() => setFilter({...filter, 'owned': 'W'})}>Work in Progress</Filter>

                            <div></div>

                            <Filter selected={!filter.format} onClick={() => setFilter({...filter, 'format': ''})}>All</Filter>
                            <Filter selected={filter.format === 'C'} onClick={() => setFilter({...filter, 'format': 'C'})}>Cartridge</Filter>
                            <Filter selected={filter.format === 'F'} onClick={() => setFilter({...filter, 'format': 'F'})}>Floppy</Filter>
                            <Filter selected={filter.format === 'T'} onClick={() => setFilter({...filter, 'format': 'T'})}>Cassette</Filter>
                            <Filter selected={filter.format === 'D'} onClick={() => setFilter({...filter, 'format': 'D'})}>Digital</Filter>

                            <div></div>

                            <Filter selected={!filter.order} onClick={() => setFilter({...filter, 'order': ''})}>A-Z</Filter>
                            <Filter selected={filter.order === 'Y'} onClick={() => setFilter({...filter, 'order': 'Y'})}>Release Year</Filter>
                            <Filter selected={filter.order === 'N'} onClick={() => setFilter({...filter, 'order': 'N'})}>Date Added</Filter>

                        </div>

                        <div className='releaseCount'>Count: {releases.length}</div>

                        <ol>
                            {releases.map((item) => (<li id={item._id} key={item._id}><Release release={item} /></li>))}
                        </ol>
                    </>

                </article>

                <aside className='Notes'>
                    { notes.length ? (<>
                            <h4>Updates</h4>
                            <ul>
                                {notes.map((item) => (<li key={item.id}><Note note={item} /></li>)
                                )}
                            </ul>
                        </>) : (<p>No Notes?</p>) }
                </aside>
            </div>
            <footer>
                <h4>Missing Info? Corrections? Hate Mail? Got something I need? Contact me at: admin@[thisdomain]</h4>
                <h4 style={{color: 'red'}}>Got a physical copy of Caren and Tangled Tentacles, that you are willing to part with? Let me know</h4>
            </footer>
        </div>
    );
}
function Filter (props) {
    return (
        <>
            [ <span onClick={props.onClick} className={props.selected ? 'filter selected' : 'filter'}>{props.children}</span> ]
        </>
    );
}
export default App;
