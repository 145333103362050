import React from 'react';
import OutboundLink from './OutboundLink';

function Release (props) {
    return (
        <>{props.release.Name}
            { (() => {
                switch(props.release.Commercial) {
                    case 'D':
                        return <span className='nyop'> [NYOP]</span>;
                    case 'N':
                        return <span className='free'> [FREE]</span>;
                    default:
                }
            })()
            } - {props.release.Year === new Date().getFullYear() ? <span className="thisYear">{props.release.Year}</span> : <>{props.release.Year}</>}{(props.release.Developer ? <> by {props.release.Developer}</> : null)} [Link: <OutboundLink eventLabel={props.release.DirectLink+"##"} to={props.release.DirectLink} target="_blank">{props.release.Publisher}</OutboundLink>]
            { (() => {
                switch(props.release.Owned) {
                    case 'N':
                        return <span> -not owned</span>;
                    case 'P':
                        return <span> -pre-ordered: {props.release.Format}</span>;
                    case 'O':
                        return <span> -on-order: {props.release.Format}</span>;
                    case 'Y':
                        return <span> {props.release.Format}</span>;
                    case 'W':
                        return <span className="wip"> [WIP]</span>;
                    default:
                        return <span> ???</span>
                    }
            })()
        }</>
    );
}

export default Release;
